/* eslint-disable no-alert, no-console */
import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';

import SkeletonLoaderPageList from '../components/loader/SkeletonLoaderPageList';
import { useAuth } from '../context/AuthContext';
import AdminLayouts from '../layouts/admin';
import AuthLayouts from '../layouts/auth';
import ForgotPassword from '../pages/auth/forgotPassword/ForgotPassword';
import SignIn from '../pages/auth/signIn/SignIn';
import SignUp from '../pages/auth/signUp/SignUp';
import Verification from '../pages/auth/verification/Verification';
import Dashboard from '../pages/dashboards';
import NotFound from '../pages/notfound';

const PrivateRoutes = ({ children }) => {
  const { isLoggedIn } = useAuth();
  console.log('PrivateRoutes', isLoggedIn);
  if (!isLoggedIn) return <Navigate to="/auth/sign-in" replace />;

  return <>{children}</>;
};

export default createBrowserRouter([
  {
    path: '/',
    element: (
      <PrivateRoutes>
        <AdminLayouts />
      </PrivateRoutes>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to={'/home'} replace />,
      },
      {
        path: '/home',
        element: (
          <React.Suspense fallback={<SkeletonLoaderPageList />}>
            <Dashboard />
          </React.Suspense>
        ),
      },
      {
        path: '/*',
        element: (
          <React.Suspense fallback={<SkeletonLoaderPageList />}>
            <NotFound />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    path: '/auth',
    element: <AuthLayouts />,
    children: [
      {
        path: '/auth/sign-in',
        element: (
          <React.Suspense fallback={'Loading...'}>
            <SignIn />
          </React.Suspense>
        ),
      },
      {
        path: '/auth/sign-up',
        element: (
          <React.Suspense fallback={'Loading...'}>
            <SignUp />
          </React.Suspense>
        ),
      },
      {
        path: '/auth/forgot-password',
        element: (
          <React.Suspense fallback={'Loading...'}>
            <ForgotPassword />
          </React.Suspense>
        ),
      },
      {
        path: '/auth/verfication',
        element: (
          <React.Suspense fallback={'Loading...'}>
            <Verification />
          </React.Suspense>
        ),
      },
    ],
  },
]);
