import React from "react";
import Chart from "react-apexcharts";

// type ChartProps = {
//   // using `interface` is also ok
//   [x: string]: any;
// };
// type ChartState = {
//   chartData: any[];
//   chartOptions: any;
// };

class ColumnChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: [],
      chartOptions: {},
    };
  }

  componentDidMount() {
    this.setState({
      chartData: this.props.chartData,
      chartOptions: this.props.chartOptions,
    });
  }

  render() {
    return (
      <Chart
        options={this.state.chartOptions}
        series={this.state.chartData}
        type="bar"
        width="100%"
        height="100%"
      />
    );
  }
}

export default ColumnChart;
