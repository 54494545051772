export default function SkeletonLoaderPageList({ row = 7 }) {
    return (
      <div
        role="status"
        className="mt-5 p-2 space-y-4  divide-y divide-gray-200 rounded  animate-pulse dark:divide-gray-700  dark:border-gray-700"
      >
        {[...Array(row)].map((e, i) => {
          return (
            <div
              className={`flex items-center justify-between ${i > 0 ? 'pt-4' : ''}`}
              key={i}
            >
              <div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              </div>
              <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
            </div>
          );
        })}
  
        <span className="sr-only">Loading...</span>
      </div>
    );
  }