import React, {
  createContext,
  useState,
} from 'react';
import Cookies from 'universal-cookie';

import { useAppDispatch } from '../hooks/useDispatch';
import { logOutAsync, loginAsync } from '../redux/reducers/userSlice';

const cookies = new Cookies();

// interface AuthContextType {
//     isLoggedIn: boolean,
//     login: (token: string, data: WebUserAccount) => void;
//     logout: () => void;
// }

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const dispatch = useAppDispatch();
  const [isLoggedIn, setLoggedIn] = useState(false);
  const login = (token, data) => {
    if (token) {
      console.log('auth:', token);
      cookies.set('token', token, { path: '/' });
      //dispatch(loginAsync(data));
      setLoggedIn(true);

    }
  };

  const logout = () => {
    cookies.remove('token', { path: '/' });
    dispatch(logOutAsync());
    setLoggedIn(false);
  };

  // useEffect(() => {
  //   // console.log("env ", process.env.REACT_APP_API_URL)
  //   const requestUser = async () => {
  //     if (cookies.get('token')) {
  //       setLoggedIn(true);
  //       console.log('asdf', isLoggedIn)
  //       // const profile = await restAPI.profile.getProfile({
  //       //   headers: { Authorization: `Bearer ${cookies.get('token')}` },
  //       // });

  //       // if (profile?.status === 200 && profile?.body) {
  //       //   setMe(profile.body);
  //       // } else {
  //       //   logout();
  //       // }
  //     } else {
  //       logout();
  //     }
  //   };

  //   if (!isLoggedIn) {
  //     requestUser();
  //   } else {
  //     // removeAuth();
  //   }
  //   // eslint-disable-next-line
  // }, []);

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
