import React from "react";
import { Routes, Route, useNavigate, Outlet } from "react-router-dom";

import FixedPlugin from "../../components/fixedPlugin/FixedPlugin";
import {useAuth} from "../../context/AuthContext";


export default function AuthLayouts() {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
    }
  }, [isLoggedIn]);

  return (
    <div>
      <div className="relative float-right h-full min-h-screen w-full dark:!bg-navy-900">
        <main className={`mx-auto min-h-screen`}>
          <FixedPlugin />
         <Outlet />
        </main>
      </div>
    </div>
  );
}
