import React from "react";
import { RouterProvider } from "react-router-dom";

import "./assets/css/Plugins.css";
import routes from "./routes";


const App = () => {
  return <RouterProvider router={routes} />;
};

export default App;
