import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
  email: '',
  webUserCompanies: [],
};

export const loginAsync = createAsyncThunk('user/fetchLogin', async (user) => {
  console.log('loginAsync', user);
  return user;
});

export const logOutAsync = createAsyncThunk('user/fetchLogout', async () => {
  return {};
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers(builder) {
    builder.addCase(loginAsync.fulfilled, (state, action) => {
      console.log('loginAsync state', state);
      console.log('loginAsync action', action.payload);
      state.email = action.payload.email;
    });
    builder.addCase(logOutAsync.fulfilled, (state) => {
      state = initialState;
    });
  },
});

// export const { loginAsync, logOutAsync } = userSlice.actions;

export default userSlice.reducer;
