import React from 'react';
import { useForm } from 'react-hook-form';
import { FcGoogle } from 'react-icons/fc';
import { useDispatch } from 'react-redux';

import Checkbox from '../../../components/checkbox';
import InputField from '../../../components/fields/InputField';
import { useAuth } from '../../../context/AuthContext';
import { translate } from '../../../i18n/index';
import Default from '../../../layouts/auth/types/Default';
import { loginAsync } from '../../../redux/reducers/userSlice';
//import client from '../../../services/client';
//import { customErrorMap } from "@/i18n/zod";

const SignIn = () => {
  const { login } = useAuth();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (formData) => {
    console.log(JSON.stringify(formData));
    // const response = await client.auth.login({
    //   body: formData,
    // });
    const data = {
      email: 'gereltod.s@gmail.com',
      webUserCompanies: [],
    };
    login(
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjIsImlhdCI6MTcwNDM3NjIwNSwiZXhwIjoxNzA0NDE5NDA1fQ.Jn9h-xvXk_8bgyKMXskbBTjabUKecYaBnE1PPPc-k7E',
      data
    );
    dispatch(loginAsync(data));
  };

  return (
    <>
      <Default
        maincard={
          <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
            {/* Sign in section */}
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
                <h3 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
                  {translate('signin.signin')}
                </h3>
                <p className="mb-9 ml-1 text-base text-gray-600">
                  {translate('signin.signin_label')}
                </p>
                <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-800 dark:text-white">
                  <div className="rounded-full text-xl">
                    <FcGoogle />
                  </div>
                  <p className="text-sm font-medium text-navy-700 dark:text-white">
                    {translate('signin.signin_google')}
                  </p>
                </div>
                <div className="mb-6 flex items-center  gap-3">
                  <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
                  <p className="text-base text-gray-600"> or </p>
                  <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
                </div>
                {/* Email */}
                <InputField
                  variant="auth"
                  extra="mb-3"
                  label={translate('signin.username')}
                  placeholder="mail@simmmple.com"
                  id="email"
                  type="text"
                  ref={{ ...register('email', {}) }}
                />

                {/* Password */}
                <InputField
                  variant="auth"
                  extra="mb-3"
                  label={`${translate('signin.password')}`}
                  placeholder="Min. 8 characters"
                  id="password"
                  type="password"
                  ref={{ ...register('password', {}) }}
                />
                {/* Checkbox */}
                <div className="mb-4 flex items-center justify-between px-2">
                  <div className="mt-2 flex items-center">
                    <Checkbox />
                    <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                      {translate('signin.keep_logged')}
                    </p>
                  </div>
                  <a
                    className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                    href="/auth/forgot-password"
                  >
                    {translate('signin.forgot_password')}
                  </a>
                </div>
                <button
                  type="submit"
                  className="linear w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                >
                  {translate('signin.login')}
                </button>
                <div className="mt-4">
                  <span className="text-sm font-medium text-navy-700 dark:text-gray-500">
                    {translate('signin.not_registered')}
                  </span>
                  <a
                    href="/auth/sign-up"
                    className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                  >
                    {translate('signin.create_account')}
                  </a>
                </div>
              </div>
            </form>
          </div>
        }
      />
    </>
  );
};

export default SignIn;
