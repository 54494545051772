import { AuthProvider } from '../context/AuthContext';
import { ErrorProvider } from '../context/errorContext';
import { ThemeProvider } from '../context/themeContext';

export default function Providers({ children }) {
  return (
    <ErrorProvider>
      <ThemeProvider>
        <AuthProvider>{children}</AuthProvider>
      </ThemeProvider>
    </ErrorProvider>
  );
}