export const en = {
    "en": {
        "sidebar": {
            "dashboard": "Dashboard",
            "sales": "Sales",
            "purchases": "Purchases",
            "transactions": "Transactions",
            "expenses": "Expenses",
            "customers": "Customers",
            "assets": "Assets",
            "branches": "Branch",
            "budges": "Budge",
            "notifications": "Notification",
            "reports": "Reports",
            "settings": "Settings",
        },
        "navbar": {
            "search": "Search",
            "hey": "Hey!",
            "notification": "Notification",
            "mark_read": "Mark read all",
            "profile": "Profile settings",
            "newsletter": "Newslater settings",
            "logout": "Logout"
        },
        "signin": {
            "signin":"Sign In",
            "signin_label":"Enter your email and password to sign in!",
            "signin_google":"Sign In with Google",
            "username":"Email*",
            "password":"Password*",
            "login":"Sign In",
            "keep_logged":"Keep me logged In",
            "forgot_password":"Forgot password?",
            "not_registered":"Not registered yet?",
            "create_account":"Create an account",
        },
        "signup": {
            "signup":"Sign Up",
            "signup_label":" Enter your email and password to sign up!",
            "signup_google":"Sign Up with Google",
            "username":"Email*",
            "firstname":"First Name*",
            "lastname":"Last Name*",
            "password":"Password*",
            "register":"Create my account",
            "policy":"By creating an account means you agree to the Terms and \nConditions, and our Privacy Policy",
            "already_registered":"Already a member!",
            "singin":" Sign In",
        },
        "forgot": {
            "forgot_password":"Forgot Your Password?",
            "forgot_label":" No problem. Just let us know your email address and \nwe'll email you a password reset link that will allow you \nto choose a new one",
            "username":"Email*",
            "btn_reset":"Email password reset link"
        }
    }
}