/* eslint-disable no-alert, no-console */
import { IoCash, IoDocumentText, IoPeople, IoBusiness, IoJournalSharp } from "react-icons/io5";
import {
    MdHome,
    MdOutlineNotifications,
    MdShoppingCart,
    MdShoppingBag,
} from "react-icons/md";
import { TbReport, TbReportMoney } from "react-icons/tb";

import { translate } from "../i18n/index";

const routes = [
    // --- Dashboards ---
    {
        name: translate("sidebar.dashboard"),
        path: "/home",
        icon: <MdHome className="text-inherit h-5 w-5" />,
        isMenu: true,
    },
    {
        name: translate("sidebar.sales"),
        path: "/sales",
        icon: <MdShoppingCart className="text-inherit h-5 w-5" />,
        isMenu: true,
    },
    {
        name: translate("sidebar.purchases"),
        path: "/goods",
        icon: <MdShoppingBag className="text-inherit h-5 w-5" />,
        isMenu: true,
    },
    // --- Transactions ---
    {
        name: translate("sidebar.transactions"),
        path: "/transactions",
        icon: <IoCash className="text-inherit h-5 w-5" />,
        isMenu: true,
    },
    {
        name: translate("sidebar.expenses"),
        path: "/expenses",
        icon: <IoDocumentText className="text-inherit h-5 w-5" />,
        isMenu: true,
    },
    // --- Customers ---
    {
        name: translate("sidebar.customers"),
        path: "/customer",
        icon: <IoPeople className="text-inherit h-5 w-5" />,
        isMenu: true,
    },
    {
        name: translate("sidebar.assets"),
        path: "/assets",
        icon: <IoJournalSharp className="text-inherit h-5 w-5" />,
        isMenu: true,
    },
    {
        name: translate("sidebar.branches"),
        path: "/branches",
        icon: <IoBusiness className="text-inherit h-5 w-5" />,
        isMenu: true,
    },
    {
        name: translate("sidebar.budges"),
        path: "/budge",
        icon: <TbReportMoney className="text-inherit h-5 w-5" />,
        isMenu: true,
    },
    {
        name: translate("sidebar.notifications"),
        path: "/notification",
        icon: <MdOutlineNotifications className="text-inherit h-5 w-5" />,
        isMenu: true,
    },
    {
        name: translate("sidebar.reports"),
        path: "/reports",
        icon: <TbReport className="text-inherit h-5 w-5" />,
        isMenu: true,
    },
    {
        name: translate("sidebar.settings"),
        path: "/settings",
        icon: <MdHome className="text-inherit h-5 w-5" />,
        isMenu: true,
        items: [

        ]
    },
];

export default routes;