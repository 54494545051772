import React, { createContext, useState } from "react";
export const ErrorContext = createContext();

function ErrorProvider(props) {
  const [error, setError] = useState();

  return (
    <ErrorContext.Provider
      value={{
        error,
        setError,
      }}
    >
      {props.children}
    </ErrorContext.Provider>
  );
}

const useError = () => React.useContext(ErrorContext);

export { ErrorProvider, useError };