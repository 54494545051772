const NotFound = () => {
    return (
      <div className="mt-3 flex h-full w-full flex-col gap-[20px] rounded-[20px] xl:flex-row">
        <div className="h-full w-full rounded-[20px]">
        not found
        </div>
  
    
      </div>
    );
  };
  
  export default NotFound;