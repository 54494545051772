import { I18n } from "i18n-js";

import { en } from "./translation/en";
import { mn } from "./translation/mn";

const i18n = new I18n();
i18n.store(mn);
i18n.store(en);
i18n.locale="mn";

export function currentLocale() {
    return i18n.locale
}

export function translate(key) {
    return i18n.t(key)
}

export function changeLanguage(lang) {
    i18n.locale = lang;
}

// export function configureI18n(locale?: string) {
//     function findBestLanguage() {
//       if (locale && allTranslations[locale]) {
//         return {
//           languageTag: locale,
//         }
//       }
//       //  languageTag: defaultLocale,
//       return (
//         RNLocalize.findBestAvailableLanguage(Object.keys(i18n.)) || {
//           languageTag: 'mn',
//           isRTL: false,
//         }
//       )
//     }
  
//     const { languageTag } = findBestLanguage()
  
//     // update layout direction
//     I18nManager.forceRTL(isRTL)
  
//     // set i18n-js config
//     i18n.translations = { [languageTag]: allTranslations[languageTag] }
//     i18n.locale = languageTag
  
  
//   }