
export const mn = {
    "mn": {
        "sidebar": {
            "dashboard": "Хянах самбар",
            "sales": "Борлуулалт",
            "purchases": "Бараа таталт",
            "transactions": "Гүйлгээ",
            "expenses": "ҮА зардал",
            "customers": "Харилцагч",
            "assets": "Хөрөнгө",
            "branches": "Байршил",
            "budges": "Төсөв",
            "notifications": "Мэдэгдэл",
            "reports": "Тайлан",
            "settings": "Тохиргоо",
        },
        "navbar": {
            "search": "Хайлт",
            "hey": "Сайн уу!",
            "notification": "Мэдэгдэл",
            "mark_read": "Бүгдийг уншсан",
            "profile": "Хувийн мэдээлэл",
            "newsletter": "Мэдэгдэл",
            "logout": "Гарах"
        },
        "signin": {
            "signin":"Нэвтрэх",
            "signin_label":"Имэйл болон нууц үгээ оруулаад нэвтрэнэ!",
            "signin_google":"Google ээр нэвтрэх",
            "username":"Имэйл*",
            "password":"Нууц үг*",
            "login":"Нэвтрэх",
            "keep_logged":"Шууд нэвтэрч байх!",
            "forgot_password":"Нууц үгээ мартсан?",
            "not_registered":"Бүртгүүлж амжаагүй бол!",
            "create_account":" Хэрэглэгч үүсгэх",
        },
        "signup": {
            "signup":"Бүртгүүлэх",
            "signup_label":"Имэйл, нууц үгээ болон бусад \nмэдээллээ оруулан бүртгүүлэх!",
            "signup_google":"Google ээр бүртгүүлэх",
            "username":"Имэйл*",
            "firstname":"Өөрийн нэр*",
            "lastname":"Эцэг (эх)-ийн нэр*",
            "password":"Нууц үг*",
            "register":"Бүртгүүлэх",
            "policy":"Бүртгэл үүсгэснээр та Нөхцөл, манай\n Нууцлалын бодлогыг зөвшөөрч байна гэсэн үг",
            "already_registered":"Бүртгүүлсэн бол!",
            "singin":" Нэвтрэх",
        },
        "forgot": {
            "forgot_password":"Нууц үгээ мартсан!",
            "forgot_label":"Асуудалгүй. Зүгээр л бидэнд имэйл хаягаа мэдэгдээрэй, бид танд \nнууц үг шинэчлэх холбоосыг имэйлээр илгээх бөгөөд \nэнэ нь танд шинийг сонгох боломжийг олгоно",
            "username":"Имэйл*",
            "btn_reset":"Имэйл рүү нүүц үгийн солих линк авах"
        }
    }
}