import { configureStore } from "@reduxjs/toolkit";

import appReducer from "./reducers/appSlice";
import userSlice from "./reducers/userSlice";

export const store = configureStore({
  reducer: {
    app: appReducer,
    user: userSlice
  },
});

// export type RootState = ReturnType<typeof store.getState>
// export type AppDispatch = typeof store.dispatch;
// export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;