import { createSlice } from "@reduxjs/toolkit";

// export type ThemeName = 'default' | 'themeGreen' | 'themeOrange' | 'themeRed' | 'themeBlue' | 'themeTeal' | 'themeBrand';

// export interface AppState {
//     appLocale: string
//     locale: string
//     hasOpened: boolean
//     isLoginPasswordActive: boolean
//     theme: ThemeName
// }

const initialState = {
    appLocale: 'mn',
    locale: 'mn',
    hasOpened: false,
    isLoginPasswordActive: true,
    theme: 'default',
}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        changeTheme: (state, action) => {
            state.theme = action.payload;
        }
    }
})

export const { changeTheme } = appSlice.actions;
export default appSlice.reducer;